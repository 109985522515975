import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import THEME, { WEB } from "../theme";
import CustomFooter from "./Footer";
import { countries, simulatedCities } from "./custom/LocationData"
import SelectCode from './custom/Select'

import { ProgressBar } from 'react-bootstrap';
import logo from "../assets/1 (1).jpg";
import avatar from "../assets/avatar.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme from "../theme";
const RegisterForm = () => {
  const navigate = useNavigate();
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [othercity, setothercity] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const formik = useFormik({
    initialValues: {
      name: "",
      ccode: "+92",
      phone: "",
      email: "",
      password: "",
      role: "user",
      status: "new",
      avatar: null,
      gender: "male",
      city: "",
      province: "",
      country: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      phone: Yup.string().required("Phone is required"),
      password: Yup.string().required("Password is required"),
      gender: Yup.string().required("Gender is required"),
      role: Yup.string().required("role is required"),
      avatar: Yup.mixed().nullable(),
      city: Yup.string().required('city is required'),
      province: Yup.string().required('province is required'),
      country: Yup.string().required('country is required'),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("ccode", values.ccode);
      formData.append("phone", values.phone);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("role", values.role);
      formData.append("status", values.status);
      formData.append("avatar", values.avatar);
      formData.append("gender", values.gender);
      formData.append("city", values.city);
      formData.append("province", values.province);
      formData.append("country", values.country);
      try {
        await axios.post(`${WEB.Backend}node/register`, formData,{
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
            console.log(progress)
          },
        });
        
        toast.success("User registered successfully!");
        navigate("/");
      } catch (error) {
        console.error("Registration error: ", error.response.data.error);

        toast.error(error.response.data.error);
      }
    },
  });

  
  //County Province City
  const handleCountryChange = (selectedCountry) => {
    const selectedCountryObj = countries.find(
      (country) => country.name === selectedCountry
    );

    if (selectedCountryObj) {
      setProvinces(selectedCountryObj.provinces || []);
      setCities([]);
      formik.setFieldValue("province", "");
      formik.setFieldValue("city", "");
    }
  };

  const handleProvinceChange = (selectedProvince) => {
    
    setCities(simulatedCities[selectedProvince] || []);
    formik.setFieldValue("city", "");
  };

  
    // Trigger the input file click
  
  const handleAvatarClick = () => {
    document.getElementById("avatarInput").click();
  };

  return (<div className='container pt-2 pb-5'>
  <ToastContainer/>
      <div className="container rounded-2 bg-light col-md-6 pb-5">
        <div className="col"style={THEME.Center}>
        <div className="p-3"style={THEME.ROW}>
        <img className='rounded me-2' style={{width:70,height:70}}src={logo}></img>
        <h5 style={{fontWeight:'bold',color:THEME.COLORS.PRIMARY}}>SK MATRIMONIALS</h5></div>
       
        <h6 style={{color:THEME.COLORS.PRIMARY}} className="text-center mb-4">Users / Match makers Register here</h6>

        <div className='col'> 
            <form onSubmit={formik.handleSubmit}>
            <div className="row">
             <div className="col">
              <label className="form-label" style={{color:theme.COLORS.PRIMARY}}>Profile Picture:</label>
              <input
                className="rounded-5"
                id="avatarInput"
                type="file"
                onChange={(e) =>
                  formik.setFieldValue("avatar", e.target.files[0])
                }
                style={{ display: "none" }}
              />
              <div className="col" onClick={handleAvatarClick}>
                {formik.values.avatar ? (
                  // Show selected picture
                  <img
                    src={URL.createObjectURL(formik.values.avatar)}
                    alt="Selected Avatar"
                    style={{ width: "20vw", height: "20vw" }}
                  />
                ) : (
                  // Show avatar image if nothing is selected
                  <img
                    src={avatar}
                    alt="Default Avatar"
                    style={{ width: "20vw", height: "20vw" }}
                  />
                )}
                </div>
              </div>
              {formik.touched.avatar && formik.errors.avatar && (
                <div className="text-dark">{formik.errors.avatar}</div>
              )}
         </div>
              
              <div style={THEME.ROW}>
                <div className="mb-3 me-2">
                  <label style={{color:theme.COLORS.PRIMARY}} className="form-label">Name:</label>
                  <input
                    type="text"
                    className="rounded-5 form-control"
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="text-dark">{formik.errors.name}</div>
                  )}
                </div>
                <div className="mb-3 me-2">
                  <label style={{color:theme.COLORS.PRIMARY}} className="form-label">Are you a ?:</label>
                  <div style={THEME.ROW}>
                    <input
                      className="rounded-5 me-2"
                      type="radio"
                      value="user"
                      checked={formik.values.role === "user"}
                      onChange={() => formik.setFieldValue("role", "user")}
                    />
                    <label style={{color:theme.COLORS.PRIMARY}} className="me-2">Candidate</label>

                    <input
                      className="rounded-5 me-2"
                      type="radio"
                      value="matchmaker"
                      checked={formik.values.role === "matchmaker"}
                      onChange={() =>
                        formik.setFieldValue("role", "matchmaker")
                      }
                    />
                    <label style={{color:theme.COLORS.PRIMARY}}>Match Maker</label>
                    {/* Add more radio options as needed */}
                  </div>
                  {formik.touched.role && formik.errors.role && (
                    <div className="text-dark">{formik.errors.role}</div>
                  )}
                </div>
                </div>
                <div style={THEME.ROW}>
                <div className="mb-3 me-2">
                  <label style={{color:theme.COLORS.PRIMARY}} className="form-label">Country Code:</label>
                  <SelectCode
        name="ccode"
        className="rounded-5 form-control"
        value={formik.values.ccode}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
                  {formik.touched.ccode && formik.errors.ccode && (
                    <div className="text-dark">{formik.errors.ccode}</div>
                  )}
                </div>
                <div className="mb-3">
                  <label style={{color:theme.COLORS.PRIMARY}} className="form-label">Phone:</label>
                  <input
                    placeholder="Phone"
                    type="number"
                    className="form-control rounded-5"
                    {...formik.getFieldProps("phone")}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className="text-dark">{formik.errors.phone}</div>
                  )}
                </div>
              </div>
              <div style={THEME.ROW}>
                <div className="mb-3 me-2">
                  <label style={{color:theme.COLORS.PRIMARY}} className="form-label">Password:</label>
                  <input
                    className="rounded-5 form-control"
                    type="password"
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="text-dark">{formik.errors.password}</div>
                  )}
                </div>
                <div className="mb-3 me-2">
                  <label style={{color:theme.COLORS.PRIMARY}} className="form-label">Gender:</label>
                  <select
                    className="rounded-5 form-control"
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="male" label="Male" />
                    <option value="female" label="Female" />
                  </select>
                  {formik.touched.gender && formik.errors.gender && (
                    <div className="text-dark">{formik.errors.gender}</div>
                  )}
                </div>

                
              </div>
             
              <div style={THEME.ROW}>
                
              <div className="col">
                  <label style={{color:theme.COLORS.PRIMARY}} className="form-label">Email (Optional):</label>
                  <input
                    type="text"
                    className="rounded-5 form-control"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="text-dark">{formik.errors.email}</div>
                  )}
                </div>
                </div>
                <div className="mb-3">
                  <div hidden>
                    <label style={{color:theme.COLORS.PRIMARY}} className="form-label">Status:</label>
                    <input
                      type="text"
                      className="rounded-5 form-control"
                      {...formik.getFieldProps("status")}
                    />
                    {formik.touched.status && formik.errors.status && (
                      <div className="text-dark">{formik.errors.status}</div>
                    )}
                  </div>
                </div>
                
              
              <ToastContainer />
              <div style={THEME.ROW}>
                <div className="mb-3 col">
                  <label style={{color:theme.COLORS.PRIMARY}} className="form-label">Country:</label>
                  <select
                    className="rounded-5 form-control"
                    name="country"
                    value={formik.values.country}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleCountryChange(e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" label="" />
                    {countries.map((country) => (
                      <option
                        key={country.name}
                        value={country.name}
                        label={country.name}
                      />
                    ))}
                  </select>
                  {formik.touched.country && formik.errors.country && (
                    <div className="text-dark">{formik.errors.country}</div>
                  )}
                </div>

                <div className="mb-3 col">
                  <label style={{color:theme.COLORS.PRIMARY}} className="form-label">Province:</label>
                  <select
                    className="rounded-5 form-control"
                    name="province"
                    value={formik.values.province}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleProvinceChange(e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    disabled={!formik.values.country}
                  >
                    <option value="" label="" />
                    {provinces.map((province) => (
                      <option
                        key={province}
                        value={province}
                        label={province}
                      />
                    ))}
                  </select>
                  {formik.touched.province && formik.errors.province && (
                    <div className="text-dark">{formik.errors.province}</div>
                  )}
                </div>

                <div className="mb-3 col">
                  <label style={{color:theme.COLORS.PRIMARY}} className="form-label">City:</label>
                  <select
                    className="rounded-5 form-control"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!formik.values.province}
                  >
                    <option value="" label="" />
                    {cities.map((city) => (
                      <option key={city} value={city} label={city} />
                    ))}
                  </select>
                  {formik.touched.city && formik.errors.city && (
                    <div className="text-dark">{formik.errors.city}</div>
                  )}
                </div>
              </div>
              <p>Not In List ? <input type="checkbox" onChange={()=>setothercity(!othercity)}/></p>
          <div className="row" style={theme.ROW}>
          {othercity!==false&&<div className="col">
                <label htmlFor="country">Country</label>
                <input
                    className="text-dark rounded-5 form-control"
                  type="text"
                  id="country"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                />
                {formik.touched.country && formik.errors.country && (
                  <div style={{ color: "red" }}>{formik.errors.country}</div>
                )}
              </div>}
          {othercity!==false&&<div className="col">
                <label htmlFor="province">Province</label>
                <input
                    className="text-dark rounded-5 form-control"
                  type="text"
                  id="province"
                  name="province"
                  value={formik.values.province}
                  onChange={formik.handleChange}
                />
                {formik.touched.province && formik.errors.province && (
                  <div style={{ color: "red" }}>{formik.errors.province}</div>
                )}
              </div>}
          {othercity!==false&&<div className="col">
                <label htmlFor="city">city</label>
                <input
                    className="text-dark rounded-5 form-control"
                  type="text"
                  id="city"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                />
                {formik.touched.city && formik.errors.city && (
                  <div style={{ color: "red" }}>{formik.errors.city}</div>
                )}
              </div>}
              </div>

              <div className="row p-3" style={THEME.ROW}>
                <button
                  className="rounded-5 col btn"
                  style={{
                    backgroundColor: THEME.COLORS.PRIMARY,
                    color: "white",
                  }}
                  type="submit"
                >
                  Register
                </button>
                {uploadProgress > 0 && (
        <ProgressBar style={{marginTop:10,marginBottom:10}} now={uploadProgress} label={`${uploadProgress}%`} />
      )}
                </div>
              <div className="row p-3">
            <Link className="rounded-5 col btn btn-outline-dark" style={{color:THEME.COLORS.PRIMARY}} to="/">
                Back
            </Link>
              </div>
                

                
             
            </form>
          </div>
        </div>
      </div>

    </div>
  );
};

export default RegisterForm;
