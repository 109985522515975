import './assets/bootstrap.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import Home from './pages/Home';
import AdminLogin from './pages/AdminLogin';
import AdminHome from './pages/AdminHome';
import AdminDashboard from './pages/AdminDashboard';
import AdminVerify from './pages/AdminVerify';
import Dashboard from './pages/Dashboard';
import Download from './pages/Download';
import AddClient from './pages/AddClient';
import GetUsers from './pages/GetUsers';
import ViewClient from './pages/ViewClient';
import ViewID from './pages/ViewClientGuest';
import FindClient from './pages/FindClient';
import ViewClientNoImage from './pages/ViewClientNoImage';
import UpdateClient from './pages/UpdateClient';
import UpdateUser from './pages/UpdateUser';
import VerifyUser from './pages/VerifyUser';
import ViewUser from './pages/ViewUser';
import ID from './pages/ViewClientID';
import UpdateAdmin from './pages/UpdateAdmin';
import SearchClient from './pages/SearchClients';
import SearchGuest from './pages/SearchGuest';
import AllClients from './pages/AllClients';
import IDSearch from './pages/IDSearch';
import Register from './pages/Register';
import ScrollToTop from './pages/ScrollToTop';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomFooter from './pages/Footer';
import { AuthProvider } from './pages/AuthContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Add the imported icons to the library
library.add(fas);
library.add(fab);


function App() {
    return (
        <div className="App">
           
            <Provider store={store}>
                <BrowserRouter basename="/">
                <AuthProvider>
                <ToastContainer/>
                <ScrollToTop/>
                    <Routes>
                        <Route path="/" element={<Home />}/>
                        <Route path="/register" element={<Register />}/>
                        <Route path="/dashboard" element={<Dashboard />}/>
                        <Route path="/app" element={<Download />}/>
                        <Route path="/addclient" element={<AddClient />}/>
                        <Route path="/admin" element={<AdminHome />}/>
                        <Route path="/admindashboard" element={<AdminDashboard />}/>
                        <Route path="/adminverify" element={<AdminVerify />}/>
                        <Route path="/searchclient" element={<SearchClient />}/>
                        <Route path="/searchGuest" element={<SearchGuest />}/>
                        <Route path="/allclients" element={<AllClients />}/>
                        <Route path="/idsearch" element={<IDSearch />}/>
                        <Route path="/allusers/:id" element={<GetUsers />}/>
                        <Route path="/viewclient/:id" element={<ViewClient />}/>
                        <Route path="/viewid/:id" element={<ViewID />}/>
                        <Route path="/id/:id" element={<ID />}/>
                        <Route path="/findclient/:id" element={<FindClient />}/>
                        <Route path="/viewclientNoImage/:id" element={<ViewClientNoImage />}/>
                        <Route path="/updateclient/:id" element={<UpdateClient />}/>
                        <Route path="/updateuser/:id" element={<UpdateUser />}/>
                        <Route path="/verifyuser/:id" element={<VerifyUser />}/>
                        <Route path="/profile/:id" element={<ViewUser />}/>
                        <Route path="/updateadmin/:id" element={<UpdateAdmin />}/>
                    </Routes>
                    <CustomFooter/>
                    </AuthProvider>
                </BrowserRouter>
            </Provider>
    <script src="./assets/bootstrap.js"></script>
    <script src="./assets/popper.min.js"></script>
    <script src="./assets/jquery-3.6.4.js"></script>
        </div>
    );
}

export default App;
