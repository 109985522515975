import React, { useState } from 'react';
import { Link ,useNavigate} from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import THEME,{WEB} from '../theme';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './AuthContext';
import logo from '../assets/1 (1).jpg';
import Search from './Search';
import theme from '../theme';


const Login = () => {
  const { logout,user } = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
    },
    validationSchema: Yup.object({
      phone: Yup.string().required('Phone is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values) => {
      try {
        // await axios.post('https://worldmuslimsmatches.com/node/login', values);
       const data= await axios.post(`${WEB.Backend}node/login`, values);
       console.log(data.data.message) 
       
        const userData=data.data.data
        // Save user information in session storage
       if(data.data.data.status!=="enabled"){
        
          logout()  
          return toast.error("Account isn't Approved Yet Please Contact Admin")
      }else{
        toast.success(data.data.message);
        await localStorage.setItem('user', JSON.stringify(userData));
        window.location.reload()
      }
        
        
  
      } catch (error) {
        console.error('Login error: ', error);
        toast.error(error.response.data.error);
      }
    },
  });

  

  
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (<div className='container pt-2 pb-5'>
  <ToastContainer/>
      <div className="container rounded-2 bg-light col-md-6 pb-5">
        <div className="col"style={theme.Center}>
        <div className="p-3"style={THEME.ROW}>
        <img className='rounded me-2' style={{width:70,height:70}}src={logo}></img>
        <h5 style={{fontWeight:'bold',color:THEME.COLORS.PRIMARY}}>SK MATRIMONIALS</h5></div>
       
        <h6 style={{color:THEME.COLORS.PRIMARY}} className="text-center mb-4">Android App Download here</h6>

        <div className='p-5 m-3 row justify-center align-center'> 
                <a
              href="https://skmatrimonial.com/SKMatrimonial.apk"
              className="btn btn-success btn-lg mb-5"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = 'https://skmatrimonial.com/SKMatrimonial.apk'; // Provide the APK download link
              }}
            >
              Download Now
            </a>
            <a
              href="https://skmatrimonial.com/SKMatrimonial.apk"
              className="btn btn-dark btn-lg"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = `${WEB.Backend}`; // Provide the APK download link
              }}
            >
              Back
            </a>
   
   
  
      
            
       
        </div>
      </div>
    </div>
  </div>
  );
};

export default Login;