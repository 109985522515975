import React, { useState } from 'react';
import { Link ,useNavigate} from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import THEME,{WEB} from '../theme';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './AuthContext';
import logo from '../assets/1 (1).jpg';
import Search from './Search';
import theme from '../theme';


const Login = () => {
  const { logout,user } = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
    },
    validationSchema: Yup.object({
      phone: Yup.string().required('Phone is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values) => {
      try {
        // await axios.post('https://worldmuslimsmatches.com/node/login', values);
       const data= await axios.post(`${WEB.Backend}node/login`, values);
       console.log(data.data.message) 
       
        const userData=data.data.data
        // Save user information in session storage
       if(data.data.data.status!=="enabled"){
        
          logout()  
          return toast.error("Account isn't Approved Yet Please Contact Admin")
      }else{
        toast.success(data.data.message);
        await localStorage.setItem('user', JSON.stringify(userData));
        window.location.reload()
      }
        
        
  
      } catch (error) {
        console.error('Login error: ', error);
        toast.error(error.response.data.error);
      }
    },
  });

  

  
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (<div className='container pt-2 pb-5'>
  <ToastContainer/>
      <div className="container rounded-2 bg-light col-md-6 pb-5">
        <div className="col"style={theme.Center}>
        <div className="p-3"style={THEME.ROW}>
        <img className='rounded me-2' style={{width:70,height:70}}src={logo}></img>
        <h5 style={{fontWeight:'bold',color:THEME.COLORS.PRIMARY}}>SK MATRIMONIALS</h5></div>
       
        <h6 style={{color:THEME.COLORS.PRIMARY}} className="text-center mb-4">Candidates / Match Makers Login here</h6>

        <div className='col'> 
     <form onSubmit={formik.handleSubmit}>
    
      <input className="mt-2 text-center rounded-5 form-control" placeholder="Phone" type="number" {...formik.getFieldProps('phone')} />
      {formik.touched.phone && formik.errors.phone && <div>{formik.errors.phone}</div>}

      <input className="mt-2  text-center rounded-5 form-control" placeholder="Password" type="password" {...formik.getFieldProps('password')} />
      {formik.touched.password && formik.errors.password && <div>{formik.errors.password}</div>}
<div className=''style={THEME.Centerflex}>
  <button style={{backgroundColor:THEME.COLORS.PRIMARY,color:'white'}} className='rounded-5 col-5 btn m-2 mt-2' type="submit">Login</button></div>

    </form>
    </div>             
    <div className='col'>       
            <div className="text-center">
              <a className='btn' href="#" onClick={handleShowModal}>
                <p style={{color:THEME.COLORS.PRIMARY,fontSize:13}}>Forgot Password?</p>
              </a>
            </div>
            <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
                            <p>Please contact Admin</p>
                            <label>Phone</label>
                            <p>+923323661898</p>
                            <label>Email</label>
                            <p>kamilworld@gmail.com</p>
                        </div>
                        <div className='row p-3'>
          <button className='btn rounded-5 col' style={{backgroundColor:THEME.COLORS.PRIMARY,color:'white'}} onClick={handleCloseModal}>
            OK
          </button>
          </div>
        </Modal.Body>
      </Modal>
      <label className='col mt-1' style={{color:THEME.COLORS.PRIMARY}}>Don't have an account ?</label>
     
            <div className="row p-3">
            <Link className="rounded-5 col btn btn-outline-dark" style={{color:THEME.COLORS.PRIMARY}} to="/register">
                Register
            </Link>
              </div> 
              <br/><br/><p style={{color:THEME.COLORS.PRIMARY}}>Find a Best <b>LIFE PARTNER</b></p>
  
        <Search />
        <div className="row">
      <div className="me-5 mt-2 col">
                    <Link to="/app" className="btn btn-success rounded text-light nav-link">Download SKMatrimonial App</Link>
                  </div>
        </div>
        
            
       
        </div>
      </div>
    </div>
  </div>
  );
};

export default Login;
