// CountryCodeSelect.js

import React from "react";

const CountryCodeSelect = ({className, name, value, onChange, onBlur }) => {
  const countryCodes = [
    "+92", "+1", "+7", "+20", "+27", "+30", "+31", "+32", "+33", "+34",
    "+36", "+39", "+40", "+41", "+44", "+49", "+51", "+52", "+54", "+55",
    "+56", "+57", "+58", "+60", "+61", "+62", "+63", "+64", "+65", "+81",
    "+82", "+84", "+86", "+90", "+91", "+92", "+93", "+94", "+95", "+98",
    // Add more country codes as needed
  ];

  return (
    <select
      className={className}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {countryCodes.map((code) => (
        <option key={code} value={code} label={code} />
      ))}
    </select>
  );
};

export default CountryCodeSelect;
