import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import axios from "axios";
import theme, { WEB } from "../theme";
import verified from '../assets/verified.png'


const UpdateUser = () => {
  const { id } = useParams();
  console.log(id)
  const navigate = useNavigate();
  const [data, setdata] = useState(null)
  const getData = async () => {
    const datadata = await axios.get(`${WEB.Backend}node/profile/${parseInt(id)}`)
    console.log(datadata.data.data)
    setdata(datadata.data.data)

  }
  useEffect(() => {
    getData()
  }, [])


  return (
    <>
      <Navbar />
      <div>



        {data && <div className='col mb-3'>
          <div className='m-1' >
            <div>
              {data.avatar !== null && <img style={{ width: '90px', height: '100%' }} className='img-fluid' src={`${WEB.Backend}node/uploads/${data.avatar}`} />}</div>
            <div className='col '>
              <div className='col bg-light text-dark p-2 rounded-2' style={{  }}>
                <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Name :<p style={{color:'black'}}>{data.name}{data.verified == "yes" ? <img height={20} width={20} src={verified} /> : ""}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Work Name : <p style={{color:'black'}}>{data.workname}</p></p></div>
                </div>
                <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Role : <p style={{color:'black'}}>{data.role}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Phone : <p style={{color:'black'}}>{data.phone}</p></p></div>
                </div>
                <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>                <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}>
                  <p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>WhatsApp : <p style={{color:'black'}}>{data.whatsapp}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}>
                    <p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Landline : <p style={{color:'black'}}>{data.landline}</p></p></div>
                </div>
                <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}>
                  <p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Area :<p style={{color:'black'}}>{data.area}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}>
                    <p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Address :<p style={{color:'black'}}>{data.address}</p></p></div></div>
                <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}>
                  <p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>City :<p style={{color:'black'}}>{data.city}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Province :<p style={{color:'black'}}>{data.province}</p></p></div></div>
                <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Country :<p style={{color:'black'}}>{data.country}</p></p></div>
                </div>
                <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Email : <p style={{color:'black'}}>{data.email}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Gender :<p style={{color:'black'}}>{data.gender}</p></p></div></div>
                <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Business Name :<p style={{color:'black'}}>{data.businessname}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Registered with Govt Organization : <p style={{color:'black'}}>{data.reggov}</p></p></div></div>
                <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Registered with Matchmakers Group : <p style={{color:'black'}}>{data.reggroup}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Non Registered Single Matchmaker : <p style={{color:'black'}}>{data.nonreg}</p></p></div>
                </div>
                <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}>
                  <p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Group Owner : <p style={{color:'black'}}>{data.groupowner}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}>
                    <p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Group Name : <p style={{color:'black'}}>{data.groupname}</p></p></div></div>
                <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Working As : <p style={{color:'black'}}>{data.workingas}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Work Area : <p style={{color:'black'}}>{data.workarea}</p></p></div></div>
                <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Charges in Advance : <p style={{color:'black'}}>{data.charges}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Your Fees Structure ? : <p style={{color:'black'}}>{data.fees}</p></p></div></div>
                <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Description : <p style={{color:'black'}}>{data.description}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>SK ID : <p style={{color:'black'}}>{data.userId}</p></p></div></div>

                <h6 className="mt-3">Social Media</h6>
                <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Facebook : <p style={{color:'black'}}>{data.fb}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Instagram : <p style={{color:'black'}}>{data.ig}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>TikTok : <p style={{color:'black'}}>{data.tt}</p></p></div>
                  <div className='col' style={{ borderWidth: "1px", borderRadius: 5, padding: 5,margin:2, borderColor: '#808080', borderStyle: 'solid' }}><p style={{ fontSize: 14, fontWeight: 'bold', color: '#808080' }}>Youtube : <p style={{color:'black'}}>{data.yt}</p></p></div>
                </div>



              </div></div></div>

        </div>}



      </div>

    </>
  );
};

export default UpdateUser;
