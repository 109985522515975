// locationData.js

export const countries = [
  {
    name: "Argentina",
    provinces: ["Buenos Aires", "Córdoba", "Santa Fe", "Mendoza"],
  },
  {
    name: "Australia",
    provinces: [
      "New South Wales",
      "Victoria",
      "Queensland",
      "Western Australia",
    ],
  },
  {
    name: "Brazil",
    provinces: ["São Paulo", "Rio de Janeiro", "Minas Gerais", "Bahia"],
  },
  {
    name: "Canada",
    provinces: ["Ontario", "Quebec", "British Columbia", "Alberta"],
  },
  {
    name: "China",
    provinces: ["Guangdong", "Shandong", "Henan", "Sichuan"],
  },
  {
    name: "Germany",
    provinces: [
      "Bavaria",
      "North Rhine-Westphalia",
      "Baden-Württemberg",
      "Lower Saxony",
    ],
  },
  {
    name: "India",
    provinces: ["Maharashtra", "Uttar Pradesh", "Karnataka", "Tamil Nadu"],
  },
  {
    name: "Italy",
    provinces: ["Lombardy", "Lazio", "Campania", "Sicily"],
  },
  {
    name: "Mexico",
    provinces: ["Mexico City", "Jalisco", "Nuevo León", "Guanajuato"],
  },
  {
    name: "Nigeria",
    provinces: ["Lagos", "Kano", "Oyo", "Rivers"],
  },
  {
    name: "Japan",
    provinces: ["Tokyo", "Osaka", "Kanagawa", "Aichi"],
  },
  {
    name: "Pakistan",
    provinces: ["Punjab", "Sindh", "Khyber Pakhtunkhwa", "Balochistan"],
  },
  {
    name: "Russia",
    provinces: [
      "Moscow",
      "Saint Petersburg",
      "Novosibirsk",
      "Krasnoyarsk Krai",
    ],
  },
  {
    name: "Saudi Arabia",
    provinces: ["Riyadh", "Mecca", "Eastern Province", "Medina"],
  },
  {
    name: "Spain",
    provinces: ["Madrid", "Catalonia", "Andalusia", "Valencia"],
  },
  {
    name: "South Africa",
    provinces: ["Gauteng", "Western Cape", "KwaZulu-Natal", "Eastern Cape"],
  },
  {
    name: "South Korea",
    provinces: ["Seoul", "Busan", "Incheon", "Gyeonggi"],
  },
  {
    name: "Turkey",
    provinces: ["Istanbul", "Ankara", "Izmir", "Bursa"],
  },
  {
    name: "United Kingdom",
    provinces: ["England", "Scotland", "Wales", "Northern Ireland"],
  },
  { name: "USA", provinces: ["California", "New York", "Texas", "Florida"] },

  // Add more countries as needed
];

export const simulatedCities = {
  California: ["Los Angeles", "San Francisco", "San Diego"],
  "New York": ["New York City", "Buffalo", "Rochester"],
  Texas: ["Houston", "Dallas", "Austin"],
  Florida: ["Miami", "Orlando", "Tampa"],
  Ontario: ["Toronto", "Ottawa", "Mississauga"],
  Quebec: ["Montreal", "Quebec City", "Laval"],
  "British Columbia": ["Vancouver", "Victoria", "Surrey"],
  Alberta: ["Calgary", "Edmonton", "Red Deer"],
  "New South Wales": ["Sydney", "Newcastle", "Wollongong"],
  Victoria: ["Melbourne", "Geelong", "Ballarat"],
  Queensland: ["Brisbane", "Gold Coast", "Sunshine Coast"],
  "Western Australia": ["Perth", "Fremantle", "Mandurah"],
  Maharashtra: ["Mumbai", "Pune", "Nagpur"],
  "Uttar Pradesh": ["Lucknow", "Kanpur", "Varanasi"],
  Karnataka: ["Bangalore", "Mysore", "Hubli"],
  "Tamil Nadu": ["Chennai", "Coimbatore", "Madurai"],
  Punjab: ["Lahore", "Faisalabad", "Rawalpindi", "Islamabad", "Sialkot"],
  Sindh: [
    "Karachi",
    "Hyderabad",
    "Larkana",
    "Nawabshah",
    "Dadu",
    "Naushahro Feroze",
  ],
  "Khyber Pakhtunkhwa": ["Peshawar", "Abbottabad", "Swat"],
  Balochistan: ["Quetta", "Gwadar", "Zhob"],
  England: ["London", "Manchester", "Birmingham"],
  Scotland: ["Edinburgh", "Glasgow", "Aberdeen"],
  Wales: ["Cardiff", "Swansea", "Newport"],
  "Northern Ireland": ["Belfast", "Derry", "Lisburn"],
  Bavaria: ["Munich", "Nuremberg", "Augsburg"],
  "North Rhine-Westphalia": ["Cologne", "Düsseldorf", "Dortmund"],
  "Baden-Württemberg": ["Stuttgart", "Karlsruhe", "Freiburg"],
  "Lower Saxony": ["Hanover", "Braunschweig", "Osnabrück"],
  Riyadh: ["Riyadh", "Dammam", "Khobar"],
  Mecca: ["Mecca", "Jeddah", "Taif"],
  "Eastern Province": ["Dammam", "Khobar", "Dhahran"],
  Medina: ["Medina", "Yanbu", "Tabuk"],
  Istanbul: ["Istanbul", "Beylikdüzü", "Üsküdar"],
  Ankara: ["Ankara", "Kecioren", "Mamak"],
  Izmir: ["Izmir", "Bornova", "Konak"],
  Bursa: ["Bursa", "Nilüfer", "Osmangazi"],
  "Buenos Aires": ["Buenos Aires City", "La Plata", "Quilmes"],
  Córdoba: ["Córdoba City", "Rosario", "Villa María"],
  "Santa Fe": ["Santa Fe City", "Rosario", "Rafaela"],
  Mendoza: ["Mendoza City", "San Rafael", "Godoy Cruz"],
  Lagos: ["Lagos City", "Ikeja", "Badagry"],
  Kano: ["Kano City", "Wudil", "Kura"],
  Oyo: ["Ibadan", "Ogbomoso", "Iseyin"],
  Rivers: ["Port Harcourt", "Obio-Akpor", "Ikwerre"],
  Madrid: ["Madrid", "Móstoles", "Alcalá de Henares"],
  Catalonia: ["Barcelona", "Hospitalet de Llobregat", "Badalona"],
  Andalusia: ["Seville", "Málaga", "Córdoba"],
  Valencia: ["Valencia", "Alicante", "Elche"],
  Lombardy: ["Milan", "Bergamo", "Brescia"],
  Lazio: ["Rome", "Latina", "Fiumicino"],
  Campania: ["Naples", "Salerno", "Pompei"],
  Sicily: ["Palermo", "Catania", "Messina"],
  Seoul: ["Seoul", "Incheon", "Suwon"],
  Busan: ["Busan", "Ulsan", "Gimhae"],
  Incheon: ["Incheon", "Bucheon", "Seongnam"],
  Gyeonggi: ["Suwon", "Goyang", "Seongnam"],
  "Mexico City": ["Mexico City", "Ecatepec", "Nezahualcóyotl"],
  Jalisco: ["Guadalajara", "Zapopan", "Tlaquepaque"],
  "Nuevo León": ["Monterrey", "San Nicolás de los Garza", "Guadalupe"],
  Guanajuato: ["León", "Irapuato", "Celaya"],
  Gauteng: ["Johannesburg", "Pretoria", "Centurion"],
  "Western Cape": ["Cape Town", "Stellenbosch", "George"],
  "KwaZulu-Natal": ["Durban", "Pietermaritzburg", "Richards Bay"],
  "Eastern Cape": ["Port Elizabeth", "East London", "Grahamstown"],
  Moscow: ["Moscow City", "Zelenograd", "Khimki"],
  "Saint Petersburg": ["Saint Petersburg City", "Pushkin", "Kolpino"],
  Novosibirsk: ["Novosibirsk City", "Berëzovyy", "Iskitim"],
  "Krasnoyarsk Krai": ["Krasnoyarsk", "Achinsk", "Norilsk"],
  Guangdong: ["Guangzhou", "Shenzhen", "Dongguan"],
  Shandong: ["Jinan", "Qingdao", "Zibo"],
  Henan: ["Zhengzhou", "Luoyang", "Kaifeng"],
  Sichuan: ["Chengdu", "Mianyang", "Leshan"],
  "São Paulo": ["São Paulo City", "Guarulhos", "Campinas"],
  "Rio de Janeiro": ["Rio de Janeiro City", "Duque de Caxias", "Nova Iguaçu"],
  "Minas Gerais": ["Belo Horizonte", "Contagem", "Uberlândia"],
  Bahia: ["Salvador", "Feira de Santana", "Vitória da Conquista"],
  Tokyo: ["Tokyo City", "Yokohama", "Osaka"],
  Osaka: ["Osaka City", "Sakai", "Higashiosaka"],
  Kanagawa: ["Yokohama", "Kawasaki", "Sagamihara"],
  Aichi: ["Nagoya", "Toyota", "Okazaki"],
  // Add more provinces and cities as needed
};
