import axios from 'axios'
import React, { useEffect, useState } from 'react'
import THEME,{ WEB } from '../theme';
import verified from '../assets/verified.png'
import Navbar from './Navbar';
import { useNavigate, useLocation, parsePath } from 'react-router-dom';
export default function GetUsers() {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setdata] = useState(null)
  const [user, setUserData] = useState(location.state.clientData);
  const [showResults, setShowResults] = useState(true);
  const [filters, setFilters] = useState({
    name: "",
    role: "",
    city: "",
    province: "",
    country: "",
    });

  const getData = async () => {
    const datadata = await axios.get(`${WEB.Backend}node/getusers`)
    console.log(datadata.data.data)
    setdata(datadata.data.data)

  }

  useEffect(() => {
    if (location.state && location.state.clientData) {
      setUserData(location.state.clientData);
    } else {

    }
  }, [location.state]);


  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    getData()
  }, [filters])

  const handleFilterChange = (filterType, value) => {
    setFilters({ ...filters, [filterType]: value });
    setShowResults(true);
  };

  const filteredClients = data && data.filter((client) => {
       

        return (
          client.name &&  client.name.toLowerCase().includes(filters.name.toLowerCase()) &&
          client.city && client.city.toLowerCase().includes(filters.city.toLowerCase()) &&
          client.country && client.country.toLowerCase().includes(filters.country.toLowerCase()) &&      
          client.province && client.province.toLowerCase().includes(filters.province.toLowerCase())&&
          client.role && client.role.toLowerCase().includes(filters.role.toLowerCase())
        );
      });

 
  
  
  const handleviewClick = client => {
    const clientphone = client.phone
    navigate(`/profile/${(clientphone)}`);
  };
  return (<>


    <div className='bg-dark'>
      <Navbar />
      <div >
      <div className="" style={THEME.Centerflex}>
      <div className="m-3" style={THEME.Center}>
      

     
       
      <div className="row" >
          <label style={{ color: 'white' }}>Name:</label>
          <input
            type="text"
            className="rounded-5 form-control col-2"
            value={filters.name}
            onChange={(e) => handleFilterChange("name", e.target.value)}
          />
        </div>

        <div className="row" style={{}}>
          <label style={{ color: 'white' }}>Province:</label>
          <input
            type="text"
            className="rounded-5 form-control col-2"
            value={filters.province}
            onChange={(e) => handleFilterChange("province", e.target.value)}
          />
        </div>

      </div>


      <div className="m-3" style={THEME.Center}>
       

      
        
        <div className="row" style={{}}>

          <label style={{ color: 'white' }}>City:</label>
          <input
            type="text"
            className="rounded-5 form-control col-2"
            value={filters.city}
            onChange={(e) => handleFilterChange("city", e.target.value)}
          />
        </div>





        <div className="row" style={{}}>

          <label style={{ color: 'white' }}>Country:</label>
          <input
            type="text"
            className="rounded-5 form-control col-2"
            value={filters.country}
            onChange={(e) => handleFilterChange("country", e.target.value)}
          />
        </div>
      </div>
    </div>
        {
         data && filteredClients.map(client => (
            <div className='bg-dark p-2 ps-3 pe-3' key={client.userId}>{client.adminrole !== "superadmin" && <div>

              <div className='col  bg-light rounded'>
                <div className='d-flex m-1'><div>
                  {client.avatar !== null && <img style={{ width: '90px', height: '100%' }} className='img-fluid' src={`${WEB.Backend}node/uploads/${client.avatar}`} />}</div>
                  <div className='col'><div className='col  text-dark p-2 rounded-2' style={{ height: 100 }}>
                    <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                      <div className='col'><p style={{ fontSize: 14, fontWeight: 'bold' }}>{client.name}{client.verified == "yes" ? <img height={20} width={20} src={verified} /> : ""}</p></div>
                      <div className='col'><p style={{ fontSize: 14, fontWeight: 'bold' }}>{client.role}</p></div>


                    </div>
                    <div className='m-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div className='col'><p style={{ fontSize: 14, fontWeight: 'bold' }}>{client.phone}</p></div>
                      <div className='col'><p style={{ fontSize: 14, fontWeight: 'bold' }}>{client.city}</p></div>

                    </div></div></div></div> </div>
                <div className='row p-1 'style={{justifyContent:'end',alignItems:'center'}}>
                


                    <button className={"col-4 rounded-5 m-1 btn btn-primary"} onClick={() => handleviewClick(client)}>View Profile</button>


                 </div>
             


            </div>

            }
            </div>
          ))
        }



      </div>




    </div>
  </>)
}
