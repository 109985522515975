import React, { useEffect,useState } from 'react';
import Navbar from './Navbar';
import { useNavigate,Link } from 'react-router-dom';
import axios from 'axios'
import theme,{WEB} from '../theme';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RingLoader } from 'react-spinners';
import Search from './SearchGuest'
export default function Dashboard() {
  const [ user,setUser ] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
    const [clients, setClientData] = useState(null);
    const [count, setCount] = useState(0);
   
     
  
    useEffect(() => {
      // Simulate a delay to showcase the loading animation
      const delay = setTimeout(() => {
        setLoading(false);
      }, 2000);
  
      return () => clearTimeout(delay);
    }, []); // Empty dependency array ensures the effect runs once when the component mounts
  
  
    const handleViewClick = client => {
      navigate(`/viewClient/${client.clientid}`, { state: { clientData: client } });
    };
    const handleFindClick = client => {
      navigate(`/findclient/${client.clientid}`, { state: { clientData: client } });
    };
    const handleUpdateClick = client => {
      navigate(`/updateclient/${client.clientid}`, { state: { clientData: client } });
    };
    const handleDeleteClick = async(client) => {
      try { const response = await axios.post(`${WEB.Backend}node/clients/delete/${client.clientid}`);

      if(response.data.message){
        setClientData((prevClients) => prevClients.filter((c) => c.clientid !== client.clientid));
        toast.success(response.data.message);
        setCount(count -1)
      }
    } catch (error) {
      toast.error("Something failed Please Try Again");
    }
     

    };


const GetUser=()=>{
const users = localStorage.getItem('user');
const data=JSON.parse(users)
if (data) {
  setUser(data);
  const userId = data && data.userId; 
  fetch(`${WEB.Backend}node/clients/view/${userId}`)
  
    .then(response => response.json())
  
    .then(data2 => {
      setClientData(data2.data)
      setCount(data2.data.length)
    }
    )

    .catch(error => console.log(error));
   
}
 } 

  useEffect(() => {
   GetUser()
  }, []);
  
  useEffect(() => {
   GetUser()
  
  }, []);
  
  useEffect(() => {
   GetUser()
  
  }, []);
  

  return (<div style={{minHeight:800}}><Navbar counts={count} />
   <div
              className="text-center bg-dark text-light  p-3"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 20,
              }}
            >
              <div className="col-2">
                 <label >Photo</label>
              </div>
              <div className="col-2">
                 <label >Name</label>
              </div>
              <div className="col-2">
                 <label >Status</label>
              </div>
              <div className="col-2">
                 <label >Age</label>
              </div>

              <div className="col-2">
                 <label >City</label>
              </div>
              
            </div>
    <div className='col'style={{justifyContent:'center',alignItems:'center',display:'flex'}}>
   
   <div>
   
   
<div style={theme.ROW}>
<h6 className='m-3 text-light text-center'>Welcome {user && user.name}</h6>
{user && user.role=="matchmaker"&&<p className='text-light m-2'>{clients && clients.length>0?`Total Clients :${clients.length}`:'No Clients'}</p>}

</div>
{user && user.role=="guest"&&<Search/>}
      {clients && clients.map((client) => (
  <div key={client.clientid}>
    
    <div className='col mb-3'>
      <div className='d-flex m-1'><div>
        <img style={{width:'90px',height:'100%'}} className='img-fluid' src={`${WEB.Backend}node/uploads/${client.avatar}`}/></div>
      <div className='col'><div className='col bg-light text-dark p-2 rounded-2'  style={{height:100}}>
    <div className='m-1'style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
    
    <div className='col'><p style={{fontSize:14,fontWeight:'bold'}}>{client.name}</p></div>
    <div className='col'><p style={{fontSize:14,fontWeight:'bold'}}>{client.maritalstatus}</p></div>
    <div className='col'><p style={{fontSize:14,fontWeight:'bold'}}>&nbsp;{client.age}</p></div>
    <div className='col'><p style={{fontSize:14,fontWeight:'bold'}}>{client.gender}</p></div>
    </div>
    <div className='m-1'style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
    <div className='col'><p style={{fontSize:14,fontWeight:'bold'}}>{client.city}</p></div> 
    <div className='col'><p style={{fontSize:14,fontWeight:'bold'}}>{client.province}</p></div>
    <div className='col'><p style={{fontSize:14,fontWeight:'bold'}}>{client.country}</p></div>
    </div></div></div></div>
      <div className='col'><div className='col text-dark p-2 rounded-2'style={{display:'flex',justifyContent:'center'}}>
    <div className='d-flex'>
      
      <button className='rounded-3 btn btn-success me-1'onClick={()=>handleViewClick(client)}>View</button>
      <button className='rounded-3 btn btn-dark me-1' onClick={()=>handleFindClick(client)}>Find Matches</button>
      </div>
      <div>
      
      <div className='d-flex'><button className='rounded-3 btn btn-warning me-1'onClick={()=>handleUpdateClick(client)}><FontAwesomeIcon icon={"edit"}/></button>
   
      <button className='rounded-3 btn btn-secondary me-1'onClick={() => {
      const textToCopy = `Profile from skmatrimonial.com is this available?\n\nProposal Details\n\nProposal Type:${client.proptype}\nSharing Type:${client.shareamount}\nAmount In Rupees:${client.amount}\nProposal Priority:${client.propprio}\nProposal For:${client.propfor}\n\nPersonal Details\n\nProfile By:${client.role}\nGender:${client.gender}\nName:${client.name}\nAge:${client.age}\nHeight:${client.height}\nMarital Status:${client.maritalstatus}\nDivorce Reason:${client.divorcereason}\nChildren:${client.children}\nEducation:${client.education}\nJob:${client.job}\nBusiness:${client.business}\nIncome:${client.income}\nLanguage:${client.language}\nCast:${client.cast}\nMaslak:${client.maslak}\nComplexion:${client.complexion}\n\nFamily Details\n\nBelongs:${client.belongs}\nFamily Status:${client.familystatus}\nHome Type:${client.hometype}\nHome Size:${client.homesize}\nFather:${client.father}\nMother:${client.mother}\nBrothers:${client.brothers}\nSisters:${client.sisters}\n\nResidential Details\n\nArea:${client.area}\nFull Address:${client.fulladdress}\nCountry:${client.country}\nProvince:${client.province}\nCity:${client.city}\n\nRequirement Details\n\nAge From:${client.reqage}\nAge To:${client.reqageto}\nCast:${client.reqcast}\nMaslak:${client.reqmaslak}\nEducation:${client.reqeducation}\nHeight:${client.reqheight}\nMarital Status:${client.reqmaritalstatus}\nFamily Status:${client.reqfamilystatus}\nCountry:${client.reqcountry}\nProvince:${client.reqprovince}\nCity:${client.reqcity}\n\nOther Details\n\nDescription:${client.description}`;
      navigator.clipboard.writeText(textToCopy)
      .then(() => {
        toast.success('Profile copied to clipboard');
      })
      .catch((err) => {
        console.error('Unable to copy text to clipboard', err);
      });
  }}><FontAwesomeIcon icon={"copy"}/></button><button className='rounded-3 btn btn-danger me-1' onClick={()=>handleDeleteClick(client)}><FontAwesomeIcon icon={"remove"}/></button>
  </div></div></div></div>
    </div>
    
    
  </div>
))} </div>    </div></div>
  );
}
