import React,{useState,useEffect} from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import THEME,{WEB} from "../theme";
import Navbar from './Navbar'
import CustomFooter from './Footer'
import { ProgressBar } from 'react-bootstrap';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { countries, simulatedCities } from "./custom/LocationData"
import SelectCode from './custom/Select'
const RegisterForm = () => {
  const [provinces, setProvinces] = useState([]);
  const [reqprovinces, setreqProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [reqcities, setreqCities] = useState([]);
  const [othercity, setothercity] = useState(false);
  const [otherreqcity, setotherreqcity] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [ user,setUser ] = useState();
  const navigate = useNavigate();

  // useEffect to update the component when the user data changes
const GetUser= ()=>{
const users = localStorage.getItem('user');
const data=JSON.parse(users)
if (data) {
  setUser(data);
} else {
 navigate('/');
}
 } 

  useEffect(() => {
   GetUser()
  }, []);
  
  useEffect(() => {
   GetUser()
  }, []);
  const mmid =user && user.userId
  const mname =user && user.name
  const mphoto =user && user.avatar
  const mstatus =user && user.status
  const mmrole =user && user.role
  const mmcontact =user && user.phone
  const mmccode =user && user.ccode
  user && console.log(user)
  console.log(mmccode)
  const formik = useFormik({
    initialValues: {
      matchmakerid:"",
      matchmakername:"",
      matchmakerphoto:"",
      gender: "",
      name: "",
      age: "",
      height: "",
      ccode: "",
      contact: "",
      cast: "",
      maslak: "",
      complexion: "",
      maritalstatus: "",
      divorcereason: "",
      children: "",
      education: "",
      job: "",
      business: "",
      income: "",
      language: "",
      belongs: "",
      country: "",
      province: "",
      city: "",
      area: "",
      familystatus: "",
      fulladdress: "",
      hometype: "",
      homesize: "",
      father: "",
      mother: "",
      brothers: "",
      sisters: "",
      reqage: "",
      reqageto: "",
      reqcast: "",
      reqmaslak: "",
      reqheight: "",
      reqfamilystatus: "",
      reqmaritalstatus: "",
      reqeducation: "",
      reqcity: "",
      reqprovince: "",
      reqcountry: "",
      proptype: "",
      shareamount: "",
      amount: "",
      propfor: "",
      propprio: "",
      description: "",
      role: "",
      avatar: [],
      photo1: [],
      photo2: [],
      status: "",
      
    },
    validationSchema: Yup.object({
      gender: Yup.string().required('Gender is required'),
      name: Yup.string().required('Name is required'),
      age: Yup.string().required('Age is required'),
      maritalstatus: Yup.string().required('Marital Status is required'),
      city: Yup.string().required('City is required'),
      province: Yup.string().required('Province is required'),
      country: Yup.string().required('Country is required'),
    }),
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append('matchmakerid', mmid);
        formData.append('matchmakername', mname);
        formData.append('matchmakerphoto', mphoto);
        formData.append('gender', values.gender);
        formData.append('name', values.name);
        formData.append('age', values.age);
        formData.append('height', values.height);
        formData.append('ccode', mmccode);
        formData.append('contact', mmcontact);
        formData.append('cast', values.cast);
        formData.append('maslak', values.maslak);
        formData.append('complexion', values.complexion);
        formData.append('maritalstatus', values.maritalstatus);
        formData.append('divorcereason', values.divorcereason);
        formData.append('children', values.children);
        formData.append('education', values.education);
        formData.append('job', values.job);
        formData.append('business', values.business);
        formData.append('income', values.income);
        formData.append('language', values.language);
        formData.append('belongs', values.belongs);
        formData.append('country', values.country);
        formData.append('province', values.province);
        formData.append('city', values.city);
        formData.append('area', values.area);
        formData.append('familystatus', values.familystatus);
        formData.append('fulladdress', values.fulladdress);
        formData.append('hometype', values.hometype);
        formData.append('homesize', values.homesize);
        formData.append('father', values.father);
        formData.append('mother', values.mother);
        formData.append('brothers', values.brothers);
        formData.append('sisters', values.sisters);
        formData.append('reqage', values.reqage);
        formData.append('reqageto', values.reqageto);
        formData.append('reqcast', values.reqcast);
        formData.append('reqmaslak', values.reqmaslak);
        formData.append('reqheight', values.reqheight);
        formData.append('reqfamilystatus', values.reqfamilystatus);
        formData.append('reqmaritalstatus', values.reqmaritalstatus);
        formData.append('reqeducation', values.reqeducation);
        formData.append('reqcity', values.reqcity);
        formData.append('reqprovince', values.reqprovince);
        formData.append('reqcountry', values.reqcountry);
        formData.append('proptype', values.proptype);
        formData.append('shareamount', values.shareamount);
        formData.append('amount', values.amount);
        formData.append('propfor', values.propfor);
        formData.append('propprio', values.propprio);
        formData.append('description', values.description);
        formData.append('role', mmrole);
        formData.append('avatar', values.avatar[0]);
        formData.append('photo1', values.photo1[0]);
        formData.append('photo2', values.photo2[0]);
        formData.append('status', mstatus);
    

        const response = await axios.post(`${WEB.Backend}node/clients/add`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
            console.log(progress)
          },
        });

        toast.success(response.data.message);
        navigate('/dashboard');
      } catch (error) {
        toast.error("Something failed Please Try Again");
        console.error("Registration error: ", error.response.data.error);
      }
    },
  });

  
  const handleCountryChange = selectedCountry => {
    const selectedCountryObj = countries.find(country => country.name === selectedCountry);

    if (selectedCountryObj) {
      setProvinces(selectedCountryObj.provinces || []);
      setCities([]);
      formik.setFieldValue('province', '');
      formik.setFieldValue('city', '');
    }
  };
  const handleProvinceChange = selectedProvince => {
    // Simulate fetching cities data based on the selected province
    // In a real-world scenario, you would need a proper API that provides this data

    setCities(simulatedCities[selectedProvince] || []);
    formik.setFieldValue('city', '');
  };

  
  const handlereqCountryChange = selectedCountry => {
    const selectedCountryObj = countries.find(country => country.name === selectedCountry);

    if (selectedCountryObj) {
      setreqProvinces(selectedCountryObj.provinces || []);
      setreqCities([]);
      formik.setFieldValue('reqprovince', '');
      formik.setFieldValue('reqcity', '');
    }
  };

    const handlereqProvinceChange = selectedProvince => {
    // Simulate fetching cities data based on the selected province
    // In a real-world scenario, you would need a proper API that provides this data

    setreqCities(simulatedCities[selectedProvince] || []);
    formik.setFieldValue('reqcity', '');
  };

  return (
    <>
    <Navbar/>
    <div className="container bg-light text-dark">
    <h3 className="mb-1 pt-5">Proposal Details</h3>
    <p className="mb-1 pt-5"><span style={{color:'red',fontSize:'20px'}}>*</span> fields are Required</p>
       <div style={THEME.ROW}>
         <div className="m-1 col-3">
          <label className="form-label">Type</label>
  <select
    className="rounded-5 form-control"
    {...formik.getFieldProps("proptype")}
  ><option value="" label="" />
    <option value="Own" label="Own" />
    <option value="Sharing Local" label="Sharing Local" />
    <option value="Sharing Abroad" label="Sharing Abroad" />

  </select>
 
          {formik.touched.proptype && formik.errors.proptype && (
            <div className="text-dark">{formik.errors.proptype}</div>
          )}
        </div>
        <div className="m-1 col">
          <label className="form-label">Sharing Type</label>
          <select
    className="rounded-5 form-control"
    {...formik.getFieldProps("shareamount")}
  ><option value="" label="" />
    <option value="No Sharing" label="No Sharing" />
    <option value="Will Give Sharing" label="Will Give Sharing" />
    <option value="Will Get Sharing" label="Will Get Sharing" />

  </select>
           {formik.touched.shareamount && formik.errors.shareamount && (
            <div className="text-dark">{formik.errors.shareamount}</div>
          )}
        </div>
        <div className="m-1 col-3">
          <label className="form-label">Share Amount</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("amount")}
          />
          {formik.touched.amount && formik.errors.amount && (
            <div className="text-dark">{formik.errors.amount}</div>
          )}
        </div>
</div>
       <div style={THEME.ROW}>
        <div className="m-1 col">
          <label className="form-label">Proposal Origin</label>
          <select
    className="rounded-5 form-control"
    {...formik.getFieldProps("propfor")}
  ><option value="" label="" />
    <option value="Local To Local" label="Local To Local" />
    <option value="Local To Abroad" label="Local To Abroad" />
    <option value="Abroad To Local" label="Abroad To Local" />
    <option value="Abroad To Abroad" label="Abroad To Abroad" />
    <option value="Both Local & Abroad" label="Both Local & Abroad" />
  </select>
 
          {formik.touched.propfor && formik.errors.propfor && (
            <div className="text-dark">{formik.errors.propfor}</div>
          )}
        </div>
        <div className="m-1 col">
          <label className="form-label">Proposal Priority</label>
          <select
    className="rounded-5 form-control"
    {...formik.getFieldProps("propprio")}
  >
    <option value="" label="" />
    <option value="Low" label="Low" />
    <option value="Normal" label="Normal" />
    <option value="Urgent" label="Urgent" />
    <option value="Very Urgent" label="Very Urgent" />
  </select>
 
          {formik.touched.propprio && formik.errors.propprio && (
            <div className="text-dark">{formik.errors.propprio}</div>
          )}
        </div>
       </div>

      <h3 className="mb-1 pt-5">Personal Details</h3>
      <form onSubmit={formik.handleSubmit}>
       <div style={THEME.ROW}>
        <div className="m-1" hidden>
          <label className="form-label">Matchmaker ID</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("matchmakerid")}
          />
          {formik.touched.matchmakerid && formik.errors.matchmakerid && (
            <div className="text-dark">{formik.errors.matchmakerid}</div>
          )}
        </div>
        <div className="m-1" hidden>
          <label className="form-label">Matchmaker Name</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("matchmakername")}
          />
          {formik.touched.matchmakername && formik.errors.matchmakername && (
            <div className="text-dark">{formik.errors.matchmakername}</div>
          )}
        </div>
        <div className="m-1" hidden>
          <label className="form-label">Matchmaker Photo</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("matchmakerphoto")}
          />
          {formik.touched.matchmakerphoto && formik.errors.matchmakerphoto && (
            <div className="text-dark">{formik.errors.matchmakerphoto}</div>
          )}
        </div>
        <div className="m-1" hidden>
          <label className="form-label">Status</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("status")}
          />
          {formik.touched.status && formik.errors.status && (
            <div className="text-dark">{formik.errors.status}</div>
          )}
        </div>
        <div className="m-1" hidden>
          <label className="form-label">Role</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("role")}
          />
          {formik.touched.role && formik.errors.role && (
            <div className="text-dark">{formik.errors.role}</div>
          )}
        </div>

        <div className="m-1 col">
  <label className="form-label">Gender <span style={{color:'red',fontSize:'20px'}}>*</span></label>
  <select
    className="rounded-5 form-control"
    {...formik.getFieldProps("gender")}
  >
    <option value="" label="" />
    <option value="male" label="Male" />
    <option value="female" label="Female" />
  </select>
  {formik.touched.gender && formik.errors.gender && (
    <div className="text-dark">{formik.errors.gender}</div>
  )}
</div>

<div className="m-1 col">
          <label className="form-label">Name <span style={{color:'red',fontSize:'20px'}}>*</span></label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name && (
            <div className="text-dark">{formik.errors.name}</div>
          )}
        </div>
        <div className="m-1 col-2">
          <label className="form-label">Age <span style={{color:'red',fontSize:'20px'}}>*</span></label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("age")}
          />
          {formik.touched.age && formik.errors.age && (
            <div className="text-dark">{formik.errors.age}</div>
          )}
        </div>
</div>
<div style={THEME.ROW}>
       <div className="m-1 col">
  <label className="form-label">Marital Status <span style={{color:'red',fontSize:'20px'}}>*</span></label>
  <select
    className="rounded-5 form-control"
    {...formik.getFieldProps("maritalstatus")}
  >
    <option value="" label="" />
    <option value="single" label="Single Never Merried" />
    <option value="nikkahbreak" label="Nikkah Break" />
    <option value="divorced" label="Divorced" />
    <option value="divorcedchild" label="Divorced (With Childrens)" />
    <option value="widowed" label="Widowed" />
    <option value="separated" label="Separated" />
    <option value="Second Marriage" label="Second Marriage" />
  </select>
  {formik.touched.maritalstatus && formik.errors.maritalstatus && (
    <div className="text-dark">{formik.errors.maritalstatus}</div>
  )}
</div>

{formik.values.maritalstatus === 'divorced'||formik.values.maritalstatus ==='divorcedchild' ? (<>
  <div className="m-1 col"><div className="m-1" >
          <label className="form-label">Reason</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("divorcereason")}
          />
          {formik.touched.divorcereason && formik.errors.divorcereason && (
            <div className="text-dark">{formik.errors.divorcereason}</div>
          )}
        </div></div>
         </>
):null}
{formik.values.maritalstatus === 'divorcedchild' && (<><div className="m-1 col"><div className="m-1">
          <label className="form-label">Childrens</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("children")}
          />
          {formik.touched.children && formik.errors.children && (
            <div className="text-dark">{formik.errors.children}</div>
          )}
        </div></div>

         </>
)}
        
</div>
       <div style={THEME.ROW}>  
        <div className="m-1 col-2">
          <label className="form-label">Height</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("height")}
          />
          {formik.touched.height && formik.errors.height && (
            <div className="text-dark">{formik.errors.height}</div>
          )}
        </div>
        <div hidden className="m-1">
          <label className="form-label">Contact</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("contact")}
          />
          {formik.touched.contact && formik.errors.contact && (
            <div className="text-dark">{formik.errors.contact}</div>
          )}
        </div>
        <div hidden className="m-1">
          <label className="form-label">Country Code</label>
          <SelectCode
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("ccode")}
          />
          {formik.touched.ccode && formik.errors.ccode && (
            <div className="text-dark">{formik.errors.ccode}</div>
          )}
        </div>
        
        <div className="m-1">
          <label className="form-label">Maslak</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("maslak")}
          />
          {formik.touched.maslak && formik.errors.maslak && (
            <div className="text-dark">{formik.errors.maslak}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Education</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("education")}
          />
          {formik.touched.education && formik.errors.education && (
            <div className="text-dark">{formik.errors.education}</div>
          )}
        </div>

        </div>
        
       <div style={THEME.ROW}>
        
        
        <div className="m-1">
          <label className="form-label">Complexion</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("complexion")}
          />
          {formik.touched.complexion && formik.errors.complexion && (
            <div className="text-dark">{formik.errors.complexion}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Cast</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("cast")}
          />
          {formik.touched.cast && formik.errors.cast && (
            <div className="text-dark">{formik.errors.cast}</div>
          )}
        </div>
        <div className="m-1 col">
          <label className="form-label">Language</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("language")}
          />
          {formik.touched.language && formik.errors.language && (
            <div className="text-dark">{formik.errors.language}</div>
          )}
        </div>
</div>
       
       <div style={THEME.ROW}>
        <div className="m-1 col">
          <label className="form-label">Job</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("job")}
          />
          {formik.touched.job && formik.errors.job && (
            <div className="text-dark">{formik.errors.job}</div>
          )}
        </div>
        <div className="m-1 col">
          <label className="form-label">Business</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("business")}
          />
          {formik.touched.business && formik.errors.business && (
            <div className="text-dark">{formik.errors.business}</div>
          )}
        </div>
       <div className="m-1 col">
          <label className="form-label">Income</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("income")}
          />
          {formik.touched.income && formik.errors.income && (
            <div className="text-dark">{formik.errors.income}</div>
          )}
        </div>
     
        </div>
        <h3 className="mb-1 pt-5">Residence Details</h3>   
        
       <div style={THEME.Centerflex}>
       <div className="m-1 col">
        <label className="form-label">Country: <span style={{color:'red',fontSize:'20px'}}>*</span></label>
        <select
          className="rounded-5 form-control me-1"
          name="country"
          value={formik.values.country}
          onChange={e => {
            formik.handleChange(e);
            handleCountryChange(e.target.value);
          }}
          onBlur={formik.handleBlur}
        >
          <option value="" label="" />
          {countries.map(country => (
            <option key={country.name} value={country.name} label={country.name} />
          ))}
        </select>
        {formik.touched.country && formik.errors.country && (
          <div className="text-dark">{formik.errors.country}</div>
        )}
      </div>

      <div className="m-1 col">
        <label className="form-label">Province: <span style={{color:'red',fontSize:'20px'}}>*</span></label>
        <select
          className="rounded-5 form-control me-1"
          name="province"
          value={formik.values.province}
          onChange={e => {
            formik.handleChange(e);
            handleProvinceChange(e.target.value);
          }}
          onBlur={formik.handleBlur}
          disabled={!formik.values.country}
        >
          <option value="" label="" />
          {provinces.map(province => (
            <option key={province} value={province} label={province} />
          ))}
        </select>
        {formik.touched.province && formik.errors.province && (
          <div className="text-dark">{formik.errors.province}</div>
        )}
      </div>

      <div className="m-1 col">
        <label className="form-label">City: <span style={{color:'red',fontSize:'20px'}}>*</span></label>
        <select
          className="rounded-5 form-control me-1"
          name="city"
          value={formik.values.city}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={!formik.values.province}
        >
          <option value="" label="" />
          {cities.map(city => (
            <option key={city} value={city} label={city} />
          ))}
        </select>
        {formik.touched.city && formik.errors.city && (
          <div className="text-dark">{formik.errors.city}</div>
        )}
      </div>
      </div>
      <p>Not In List ? <input type="checkbox" onChange={()=>setothercity(!othercity)}/></p>
          <div className="row" style={THEME.ROW}>
          {othercity!==false&&<div className="col">
                <label htmlFor="country">Country</label>
                <input
                    className="text-dark rounded-5 form-control"
                  type="text"
                  id="country"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                />
                {formik.touched.country && formik.errors.country && (
                  <div style={{ color: "red" }}>{formik.errors.country}</div>
                )}
              </div>}
          {othercity!==false&&<div className="col">
                <label htmlFor="province">Province</label>
                <input
                    className="text-dark rounded-5 form-control"
                  type="text"
                  id="province"
                  name="province"
                  value={formik.values.province}
                  onChange={formik.handleChange}
                />
                {formik.touched.province && formik.errors.province && (
                  <div style={{ color: "red" }}>{formik.errors.province}</div>
                )}
              </div>}
          {othercity!==false&&<div className="col">
                <label htmlFor="city">city</label>
                <input
                    className="text-dark rounded-5 form-control"
                  type="text"
                  id="city"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                />
                {formik.touched.city && formik.errors.city && (
                  <div style={{ color: "red" }}>{formik.errors.city}</div>
                )}
              </div>}
              </div>
      
       <div style={THEME.ROW}>
        <div className="m-1">
          <label className="form-label">Belongs</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("belongs")}
          />
          {formik.touched.belongs && formik.errors.belongs && (
            <div className="text-dark">{formik.errors.belongs}</div>
          )}
        </div>
        
          <div className="m-1">
          <label className="form-label">Area</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("area")}
          />
          {formik.touched.area && formik.errors.area && (
            <div className="text-dark">{formik.errors.area}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Full Address</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("fulladdress")}
          />
          {formik.touched.fulladdress && formik.errors.fulladdress && (
            <div className="text-dark">{formik.errors.fulladdress}</div>
          )}
        </div></div>
        <h3 className="mb-1 pt-5">Family Details</h3>
       <div style={THEME.ROW}><div className="m-1">
          <label className="form-label">Family Status</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("familystatus")}
          />
          {formik.touched.familystatus && formik.errors.familystatus && (
            <div className="text-dark">{formik.errors.familystatus}</div>
          )}
        </div>
       
        <div className="m-1">
          <label className="form-label">Home Type</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("hometype")}
          />
          {formik.touched.hometype && formik.errors.hometype && (
            <div className="text-dark">{formik.errors.hometype}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Home Size</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("homesize")}
          />
          {formik.touched.homesize && formik.errors.homesize && (
            <div className="text-dark">{formik.errors.homesize}</div>
          )}
        </div>
       </div>
       <div style={THEME.ROW}><div className="m-1">
          <label className="form-label">Father</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("father")}
          />
          {formik.touched.father && formik.errors.father && (
            <div className="text-dark">{formik.errors.father}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Mother</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("mother")}
          />
          {formik.touched.mother && formik.errors.mother && (
            <div className="text-dark">{formik.errors.mother}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Brothers</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("brothers")}
          />
          {formik.touched.brothers && formik.errors.brothers && (
            <div className="text-dark">{formik.errors.brothers}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Sisters</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("sisters")}
          />
          {formik.touched.sisters && formik.errors.sisters && (
            <div className="text-dark">{formik.errors.sisters}</div>
          )}
        </div>
     </div>
     <h3 className="mb-1 pt-5">Requirements</h3>
       <div style={THEME.ROW}>
        <div className="m-1">
          <label className="form-label">Age From</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("reqage")}
          />
          {formik.touched.reqage && formik.errors.reqage && (
            <div className="text-dark">{formik.errors.reqage}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Age To</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("reqageto")}
          />
          {formik.touched.reqageto && formik.errors.reqageto && (
            <div className="text-dark">{formik.errors.reqageto}</div>
          )}
        </div>
        </div>
        <div style={THEME.ROW}>
        <div className="m-1">
  <label className="form-label">Marital Status</label>
  <select
    className="rounded-5 form-control"
    {...formik.getFieldProps("reqmaritalstatus")}
  >
    <option value="" label="" />
    <option value="single" label="Single Never Merried" />
    <option value="nikkahbreak" label="Nikkah Break" />
    <option value="divorced" label="Divorced" />
    <option value="divorcedchild" label="Divorced (With Childrens)" />
    <option value="widowed" label="Widowed" />
    <option value="separated" label="Separated" />
    <option value="Second Marriage" label="Second Marriage" />
  </select>
  {formik.touched.reqmaritalstatus && formik.errors.reqmaritalstatus && (
    <div className="text-dark">{formik.errors.reqmaritalstatus}</div>
  )}
</div>
<div className="m-1">
          <label className="form-label">Height</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("reqheight")}
          />
          {formik.touched.reqheight && formik.errors.reqheight && (
            <div className="text-dark">{formik.errors.reqheight}</div>
          )}
        </div>
       </div>
       <div style={THEME.ROW}>  
       <div className="m-1">
          <label className="form-label">Maslak</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("reqmaslak")}
          />
          {formik.touched.reqmaslak && formik.errors.reqmaslak && (
            <div className="text-dark">{formik.errors.reqmaslak}</div>
          )}
        </div>
       <div className="m-1">
          <label className="form-label">Education</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("reqeducation")}
          />
          {formik.touched.reqeducation && formik.errors.reqeducation && (
            <div className="text-dark">{formik.errors.reqeducation}</div>
          )}
        </div>
       
        <div className="m-1">
          <label className="form-label">Cast</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("reqcast")}
          />
          {formik.touched.reqcast && formik.errors.reqcast && (
            <div className="text-dark">{formik.errors.reqcast}</div>
          )}
        </div>
        
       </div>
       <div style={THEME.ROW}>  
       <div className="m-1">
          <label className="form-label">Family Status</label>
          <input
            type="text"
            className="rounded-5 form-control"
            {...formik.getFieldProps("reqfamilystatus")}
          />
          {formik.touched.reqfamilystatus && formik.errors.reqfamilystatus && (
            <div className="text-dark">{formik.errors.reqfamilystatus}</div>
          )}
        </div>
        <div className="m-1">
        <label className="form-label">Country:</label>
        <select
          className="rounded-5 form-control me-1"
          name="reqcountry"
          value={formik.values.reqcountry}
          onChange={e => {
            formik.handleChange(e);
            handlereqCountryChange(e.target.value);
          }}
          onBlur={formik.handleBlur}
        >
          <option value="" label="" />
          {countries.map(country => (
            <option key={country.name} value={country.name} label={country.name} />
          ))}
        </select>
        {formik.touched.reqcountry && formik.errors.reqcountry && (
          <div className="text-dark">{formik.errors.reqcountry}</div>
        )}
      </div>
               
        
</div>
       <div style={THEME.ROW}>
        
       <div className="m-1 col">
        <label className="form-label">Province:</label>
        <select
          className="col rounded-5 form-control me-1"
          name="reqprovince"
          value={formik.values.reqprovince}
          onChange={e => {
            formik.handleChange(e);
            handlereqProvinceChange(e.target.value);
          }}
          onBlur={formik.handleBlur}
          disabled={!formik.values.reqcountry}
        >
          <option value="" label="" />
          {reqprovinces.map(province => (
            <option key={province} value={province} label={province} />
          ))}
        </select>
        {formik.touched.reqprovince && formik.errors.reqprovince && (
          <div className="text-dark">{formik.errors.reqprovince}</div>
        )}
      </div>
      <div className="m-1 col">
        <label className="form-label">City:</label>
        <select
          className="col rounded-5 form-control me-1"
          name="reqcity"
          value={formik.values.reqcity}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={!formik.values.reqprovince}
        >
          <option value="" label="" />
          {reqcities.map(city => (
            <option key={city} value={city} label={city} />
          ))}
        </select>
        {formik.touched.reqcity && formik.errors.reqcity && (
          <div className="text-dark">{formik.errors.reqcity}</div>
        )}
      </div>
      

      
        </div>
        <p>Not In the List ? <input type="checkbox" onChange={()=>setotherreqcity(!otherreqcity)}/></p>
          <div className="row" style={THEME.ROW}>
          {otherreqcity!==false&&<div className="col">
                <label htmlFor="reqcountry">Country</label>
                <input
                    className="text-dark rounded-5 form-control"
                  type="text"
                  id="reqcountry"
                  name="reqcountry"
                  value={formik.values.reqcountry}
                  onChange={formik.handleChange}
                />
                {formik.touched.reqcountry && formik.errors.reqcountry && (
                  <div style={{ color: "red" }}>{formik.errors.reqcountry}</div>
                )}
              </div>}
          {otherreqcity!==false&&<div className="col">
                <label htmlFor="reqprovince">Province</label>
                <input
                    className="text-dark rounded-5 form-control"
                  type="text"
                  id="reqprovince"
                  name="reqprovince"
                  value={formik.values.reqprovince}
                  onChange={formik.handleChange}
                />
                {formik.touched.reqprovince && formik.errors.reqprovince && (
                  <div style={{ color: "red" }}>{formik.errors.reqprovince}</div>
                )}
              </div>}
          {otherreqcity!==false&&<div className="col">
                <label htmlFor="reqcity">city</label>
                <input
                    className="text-dark rounded-5 form-control"
                  type="text"
                  id="reqcity"
                  name="reqcity"
                  value={formik.values.reqcity}
                  onChange={formik.handleChange}
                />
                {formik.touched.reqcity && formik.errors.reqcity && (
                  <div style={{ color: "red" }}>{formik.errors.reqcity}</div>
                )}
              </div>}
              </div>
       <div style={THEME.ROW}> 
       <div className="m-1 col">
  <label className="form-label">Description</label>
  <textarea
    rows="3"
    className="rounded-5 form-control me-1"
    {...formik.getFieldProps("description")}
  />
  {formik.touched.description && formik.errors.description && (
    <div className="text-dark">{formik.errors.description}</div>
  )}
</div>
        </div>
        <h3 className="mb-1 pt-5">Photos</h3>
        
       <div style={THEME.ROW}>
       <div className="col m-1"><label htmlFor="avatar">Photo:</label>
        <input type="file" className="col form-control" onChange={(event) => formik.setFieldValue('avatar', event.currentTarget.files)} />
</div>

        <div className="col m-1"><label htmlFor="photo1">Photo 2:</label>
        <input type="file" className="col form-control" onChange={(event) => formik.setFieldValue('photo1', event.currentTarget.files)} />
</div>
<div className="col m-1"><label htmlFor="photo2">Photo 3:</label>
        <input type="file" className="col form-control" onChange={(event) => formik.setFieldValue('photo2', event.currentTarget.files)} />
</div></div>
  
                
{uploadProgress > 0 && (
        <ProgressBar style={{marginTop:10,marginBottom:10}} now={uploadProgress} label={`${uploadProgress}%`} />
      )}
        
        
        
<div className="row"><button type="submit" className="btn btn-dark text-white m-5 col rounded-5">
          Submit
        </button></div>
        
      </form>
    </div>
   
    </>
  );
};

export default RegisterForm;
