import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Navbar from "./Navbar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import theme, { WEB } from "../theme";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.object().shape({
  workname: Yup.string().required("Workname is required"),
  businessname: Yup.string().required("Business Name is Required"),
  whatsapp: Yup.string().required("Whatsapp is Required"),
  landline: Yup.string().required("Landline is Required"),
  cnic: Yup.string().required("CNIC is Required"),
  address: Yup.string().required("Address is Required"),
  area: Yup.string().required("Area is Required"),
  groupowner: Yup.string().required("Group Owner is Required"),
  groupname: Yup.string().required("Group Name is Required"),
  description: Yup.string().required("Description is Required"),
  workingas: Yup.string().required("Working As is Required"),
  charges: Yup.string().required("Charges is Required"),
  fees: Yup.string().required("Fees Structure is Required"),
  workarea: Yup.string().required("Work Area is Required"),

});

const UpdateUser = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUserData] = useState(location.state.clientData);

  useEffect(() => {
    if (location.state && location.state.clientData) {
      setUserData(location.state.clientData);
    } else {

    }
  }, [location.state]);

  const userId = user.userId;
  // ccode: user.ccode,


  const formik = useFormik({
    initialValues: {
      workname: "",
      businessname: "",
      reggov: "",
      reggroup: "",
      nonreg: "",
      whatsapp: "",
      landline: "",
      cnic: "",
      address: "",
      area: "",
      groupowner: "",
      groupname: "",
      description: "",
      workingas: "",
      charges: "",
      fees: "",
      workarea: "",
      fb: "",
      yt: "",
      ig: "",
      tt: "",
      verified: "pending"
    },
    validationSchema,
    onSubmit: async (values) => {


      // Update user information
      axios
        .put(`${WEB.Backend}node/verify/${userId}`, values)
        .then((response) => {
          console.log(response.data.message);
          toast.success(response.data.message);
          console.log(response)
          response && response.data.message == "Saved" && localStorage.setItem('user', JSON.stringify(response.data.data));
          navigate("/dashboard");
        })
        .catch((error) => {
          console.error("Error updating user:", error);
          toast.error("Something failed. Please try again.");
        });
    },
  });

  return (
    <>
      <Navbar />
      <div className="container ">
        <div className="container bg-light mb-3 mt-2 text-dark">
          <div className="container col pt-2">


            <h5 style={{ color: 'black' }}>Details Required for Verification</h5>

            <form onSubmit={formik.handleSubmit}>

              <div className="row">

                <div className="col">
                  <label htmlFor="workname">Work Name (Nickname)</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="workname"
                    name="workname"
                    value={formik.values.workname}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.workname && formik.errors.workname && (
                    <div style={{ color: "red" }}>{formik.errors.workname}</div>
                  )}
                </div>
                <div className="col">
                  <label htmlFor="phone">Bureau/Business Name</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="businessname"
                    name="businessname"
                    value={formik.values.businessname}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.businessname && formik.errors.businessname && (
                    <div style={{ color: "red" }}>{formik.errors.businessname}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="reggov">Registered with a Govt Organization</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="reggov"
                    name="reggov"
                    value={formik.values.reggov}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.reggov && formik.errors.reggov && (
                    <div style={{ color: "red" }}>{formik.errors.reggov}</div>
                  )}
                </div>
                <div className="col">
                  <label htmlFor="reggroup">Registered with a Matchmakers Group</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="reggroup"
                    name="reggroup"
                    value={formik.values.reggroup}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.reggroup && formik.errors.reggroup && (
                    <div style={{ color: "red" }}>{formik.errors.reggroup}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="nonreg">Non Registered Single Matchmaker</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="nonreg"
                    name="nonreg"
                    value={formik.values.nonreg}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.nonreg && formik.errors.nonreg && (
                    <div style={{ color: "red" }}>{formik.errors.nonreg}</div>
                  )}
                </div>
              </div>
              <h6 className="mt-3">Contact Information</h6>
              <div className="row">
                <div className="col">
                  <label htmlFor="whatsapp">WhatsApp</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="whatsapp"
                    name="whatsapp"
                    placeholder="e.g +923001232123"
                    value={formik.values.whatsapp}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.whatsapp && formik.errors.whatsapp && (
                    <div style={{ color: "red" }}>{formik.errors.whatsapp}</div>
                  )}
                </div>
                <div className="col">
                  <label htmlFor="landline">Landline</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="landline"
                    name="landline"
                    value={formik.values.landline}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.landline && formik.errors.landline && (
                    <div style={{ color: "red" }}>{formik.errors.landline}</div>
                  )}
                </div>
              </div>
              <div className="row"><div className="col">
                <label htmlFor="cnic">CNIC/Passport/SocialID</label>
                <input
                  className="text-dark  form-control"
                  type="text"
                  id="cnic"
                  name="cnic"
                  value={formik.values.cnic}
                  onChange={formik.handleChange}
                />
                {formik.touched.cnic && formik.errors.cnic && (
                  <div style={{ color: "red" }}>{formik.errors.cnic}</div>
                )}
              </div>
                <div className="col">
                  <label htmlFor="area">Area</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="area"
                    name="area"
                    value={formik.values.area}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.area && formik.errors.area && (
                    <div style={{ color: "red" }}>{formik.errors.area}</div>
                  )}

                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="address">Address</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="address"
                    name="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.address && formik.errors.address && (
                    <div style={{ color: "red" }}>{formik.errors.address}</div>
                  )}
                </div></div><div className="row">
                <div className="col">
                  <label htmlFor="groupowner">Group Owner (Admin)</label>
                  <select
                    className="  form-control"
                    name="groupowner"
                    value={formik.values.groupowner}
                    onChange={formik.handleChange}>
                    <option value="" label="" />
                    <option value="Yes" label="Yes" />
                    <option value="No" label="No" />
                  </select>
                  {formik.touched.groupowner && formik.errors.groupowner && (
                    <div style={{ color: "red" }}>{formik.errors.groupowner}</div>
                  )}
                </div>
                <div className="col">
                  <label htmlFor="groupname">Group Name</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="groupname"
                    name="groupname"
                    value={formik.values.groupname}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.groupname && formik.errors.groupname && (
                    <div style={{ color: "red" }}>{formik.errors.groupname}</div>
                  )}
                </div>
              </div>
              <div className="row">

                <div className="col">
                  <label htmlFor="description">Describe your Self</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div style={{ color: "red" }}>{formik.errors.description}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="workingas">Working As</label>
                  <select
                    className="text-dark form-control"
                    name="workingas"
                    value={formik.values.workingas}
                    onChange={formik.handleChange}>
                    <option value="" label="" />
                    <option value="Professional" label="Professional" />
                    <option value="FeaSabilillah" label="FeaSabilillah" /></select>
                  {formik.touched.workingas && formik.errors.workingas && (
                    <div style={{ color: "red" }}>{formik.errors.workingas}</div>
                  )}
                </div>
                <div className="col">
                  <label htmlFor="workingarea">Work Area</label>
                  <select
                    className="  form-control"
                    name="workarea"
                    value={formik.values.workarea}
                    onChange={formik.handleChange}

                  >
                    <option value="" label="" />
                    <option value="Locally" label="Locally" />
                    <option value="Internationally" label="Internationally" />

                  </select>
                  {formik.touched.workarea && formik.errors.workarea && (
                    <div className="text-dark">{formik.errors.workarea}</div>
                  )}
                </div>

              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="charges">Charges in Advance</label>
                  <select
                    className="  form-control"
                    name="charges"
                    value={formik.values.charges}
                    onChange={formik.handleChange}

                  >

                    <option value="" label="" />
                    <option value="Yes" label="Yes" />
                    <option value="No" label="No" />

                  </select>
                  {formik.touched.charges && formik.errors.charges && (
                    <div style={{ color: "red" }}>{formik.errors.charges}</div>
                  )}
                </div>
                <div className="col">
                  <label htmlFor="fees">Your Fees Structure</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="fees"
                    name="fees"
                    value={formik.values.fees}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.fees && formik.errors.fees && (
                    <div style={{ color: "red" }}>{formik.errors.fees}</div>
                  )}
                </div>
              </div>
              <h6 className="mt-3">Social Media (Optional)</h6>
              <div className="row"><div className="col">
                <label htmlFor="fb">Facebook</label>
                <input
                  className="text-dark  form-control"
                  type="text"
                  id="fb"
                  name="fb"
                  value={formik.values.fb}
                  onChange={formik.handleChange}
                />
                {formik.touched.fb && formik.errors.fb && (
                  <div style={{ color: "red" }}>{formik.errors.fb}</div>
                )}
              </div>
                <div className="col">
                  <label htmlFor="yt">Youtube</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="yt"
                    name="yt"
                    value={formik.values.yt}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.yt && formik.errors.yt && (
                    <div style={{ color: "red" }}>{formik.errors.yt}</div>
                  )}
                </div>
                <div className="col">
                  <label htmlFor="ig">Instagram</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="ig"
                    name="ig"
                    value={formik.values.ig}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.ig && formik.errors.ig && (
                    <div style={{ color: "red" }}>{formik.errors.ig}</div>
                  )}
                </div>
                <div className="col">
                  <label htmlFor="tt">TikTok</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="tt"
                    name="tt"
                    value={formik.values.tt}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.tt && formik.errors.tt && (
                    <div style={{ color: "red" }}>{formik.errors.tt}</div>
                  )}
                </div>
                <div className="col" hidden>
                  <label htmlFor="verified">verified</label>
                  <input
                    className="text-dark  form-control"
                    type="text"
                    id="verified"
                    name="verified"
                    value={"no"}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.verified && formik.errors.verified && (
                    <div style={{ color: "red" }}>{formik.errors.verified}</div>
                  )}
                </div>
              </div>


              <div className="row" style={theme.ROW}>



                <button
                  className="col  btn btn-dark text-light m-3"
                  onClick={() => {

                    formik.handleSubmit();

                  }}
                >
                  Submit Profile</button>

              </div>


            </form>


            <div className="row">

            </div>
          </div></div>

      </div>

    </>
  );
};

export default UpdateUser;
