// Footer.js
import React from "react";
import logo from "../assets/1 (32).jpg";
import THEME from "../theme";
import { Link } from "react-router-dom";

const CustomFooter = () => {
  return (
    <footer
      style={THEME.FooterStyle}
      className="text-center text-white text-lg-start"
    >
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
            <img
              className="img-fluid"
              src={logo}
              style={{ width: "90%", height: "90%" }}
            />
          </div>

          {/* Navigation Section */}
          <div className="col-lg-3 col-md-6">
            <p style={{ textAlign: "left" }}>
              Welcome to SK Matrimonial. A web application project by SK GROUPS
              of International Match Makers. For fastest and better working of
              each and every match maker. This web application is far more
              better than thousands of WhatsApp Matrimonial Groups.
              <br />
              <br />
              Join SKMatrimonial today and start your journey to successful
              match making in a fastest way.
              <br />
              <br />
              Please also visit{" "}
              <a href="https://skmatrimonials.com">SKMatrimonials.com</a> for
              abroad profiles.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0"></div>
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="">Contact Admin</h5>
            <ul className="list-unstyled mb-0">
              <li>
                <Link
                  to="#link1"
                  className="text-white btn"
                  style={{ fontSize: 14, textAlign: "left" }}
                >
                  Location: Office No. R-200, Gulshan-e-Areesha, Scheme 33, Main
                  University Road, Karachi.
                </Link>
              </li>
              <li>
                <Link
                  to="#link2"
                  className="text-white btn"
                  style={{ fontSize: 14 }}
                >
                  Phone (Local): +92 332 3661898
                </Link>
                <br />
                <Link
                  to="#link2"
                  className="text-white btn"
                  style={{ fontSize: 14 }}
                >
                  Phone (International): +1 (929) 303-7335
                </Link>
                <br />
                <Link
                  to="#link2"
                  className="text-white btn"
                  style={{ fontSize: 14 }}
                >
                  Email Us : admin@skmatrimonials.com
                </Link>
              </li>
              {/* Add more links as needed */}
            </ul>
          </div>
        </div>
      </div>
      {/* Copyright Section */}
      <div className="ps-3 pe-3 row text-dark bg-light">
        <div className="col mt-3 text-center text-dark bg-light">
          <h6 className="">Copyright</h6>
          <p>
            SKMatrimonial {new Date().getFullYear()}
            <br /> All rights reserved.
          </p>
        </div>
        <div className="col mb-4 mt-3 text-center">
          <h6 className="">Powered by</h6>
          <Link to="https://tahiriservices.com" className="text-dark btn">
            <p>TahiriServices.com</p>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default CustomFooter;
