import React, { useEffect,useState } from 'react';
import Navbar from './Navbar';
import { useNavigate,Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from 'axios'
import theme,{WEB} from '../theme';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import verified from '../assets/verified.png'

export default function Dashboard() {
  const [ user,setUser ] = useState();
  const navigate = useNavigate();
  
    const [clients, setClientData] = useState(null);
    const [userstatus, setuserStatus] = useState(null);
    const [sortBy, setSortBy] = useState('verified'); // Default sorting by name
    const [sortOrder, setSortOrder] = useState('asc'); // Default sorting order
    const [showModal, setShowModal] = useState(false);
    const [clientToDelete, setClientToDelete] = useState(null);
  
    const [count, setCount] = useState(0);
   
     
  
  
   
    const handleUpdateClick = client => {
      navigate(`/updateadmin/${client.userId}`, { state: { clientData: client } });
    };
    const handleDeleteClick = async(client) => {
      try { const response = await axios.post(`${WEB.Backend}node/delete/${clientToDelete.userId}`);

      if(response.data.message){
        setClientData((prevClients) => prevClients.filter((c) => c.userId !== clientToDelete.userId));
        toast.success(response.data.message);
        setCount(count -1)
        setShowModal(false)
      }
    } catch (error) {
      toast.error("Something failed Please Try Again");
    }
     

    };


const GetUser=()=>{
const users = localStorage.getItem('user');
const data=JSON.parse(users)
if (data) {
  setUser(data);
  const userId = data && data.userId; 
  fetch(`${WEB.Backend}node/getusers`)
  
    .then(response => response.json())
  
    .then(data2 => {
      setClientData(data2.data)
      console.log(data2.data)
      setCount(data2.data.length)
    }
    )

    .catch(error => console.log(error));
   
}
 } 

  useEffect(() => {
   GetUser()
  }, []);
  
  useEffect(() => {
   GetUser()
  
  }, []);
  
  useEffect(() => {
   GetUser()
  
  }, []);
  const handleLoginAsClick = (client) => {
    // Perform login action using the credentials of the selected client
    // For example, you can store the client's information in localStorage
    localStorage.setItem('user', JSON.stringify(client));
    
    // Redirect or perform any actions needed after logging in as the client
    // For example, redirect to a different page
    navigate('/dashboard'); // Replace '/dashboard' with the desired URL
  };  
  const handleVerifiedClick = (client) => {
   console.log(client.name)
   console.log(client.userId)
 

  
  const data= {
    verified:client.verified=="pending"?"yes":"no"
  }
  axios
   .put(`${WEB.Backend}node/updateuserverified/${client.userId}`, data)
   .then((response) => {
     console.log(response.data.message);
     toast.success(response.data.message);
     window.location.reload()
    })
   .catch((error) => {
     console.error("Error updating user:", error);
     toast.error("Something failed. Please try again.");
   });

  };
  
  const handleSortChange = (event) => {
    const selectedSort = event.target.value;
    setSortBy(selectedSort);
  };
  const handleOrderChange = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };
  const sortedClients = clients ? [...clients] : [];  
  sortedClients.sort((a, b) => {
    const compareValue = (valueA, valueB) => {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    };

    const orderMultiplier = sortOrder === 'asc' ? 1 : -1;

    switch (sortBy) {
      case 'name':
        return compareValue(a.name, b.name) * orderMultiplier;
      case 'number':
        return compareValue(a.phone, b.phone) * orderMultiplier;
      case 'role':
        return compareValue(a.role, b.role) * orderMultiplier;
      case 'status':
        return compareValue(a.status, b.status) * orderMultiplier;
      case 'verified':
        return compareValue(a.verified, b.verified) * orderMultiplier;
      default:
        return 0;
    }
  });
  const handleShowModal = (client) => {
    setShowModal(true);
    setClientToDelete(client)};
  const handleCloseModal = () => setShowModal(false);
  const handleviewClick = client => {
    const clientphone = client.phone
    navigate(`/profile/${(clientphone)}`);
  };
  return (
    <>
  
    <div className='container'>
      <div className='container bg-light'>
     
     


      {clients && sortedClients.map((client) => (
  <>{client.verified==="pending"&&<div key={client.clientid}>
    <p>Verification Pending</p>
  <div className='col mb-3'>
    <div className='d-flex m-1'><div>
      {client.avatar!==null&&<img style={{width:'90px',height:'100%'}} className='img-fluid' src={`${WEB.Backend}node/uploads/${client.avatar}`}/>}</div>
    <div className='col'><div className='col bg-light text-dark p-2 rounded-2'  style={{height:100}}>
  <div className='m-1'style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
  
  <div className='col'><p style={{fontSize:14,fontWeight:'bold'}}>{client.name}{client.verified==="yes"?<img src={verified} height={20}width={20}/>:""}</p></div>

  <div className='col'><p style={{fontSize:14,fontWeight:'bold'}}>{client.role}</p></div>
 
  
  </div>
  <div className='m-1'style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
  <div className='col'><p style={{fontSize:14,fontWeight:'bold'}}>{client.phone}</p></div>
  <div className='col'><p style={{fontSize:14,fontWeight:'bold'}}>{client.city}</p></div> 

  </div></div></div></div>
    <div className='col'><div className='col text-dark  rounded-2'style={{display:'flex',justifyContent:'center'}}>
  <div className='col'>
    
  <button className={client.verified=="pending"?"col rounded-5 m-1 btn btn-success":"col rounded-5 m-1 btn btn-dark"} onClick={() => handleVerifiedClick(client)}>{client.verified=="pending"?"Verify":"UnVerify"}</button>
  

   <button className='col rounded-5 m-1 btn btn-primary' onClick={() => handleviewClick(client)}>View Profile</button>
   
    </div>
    <div>
    
    </div></div></div>
  </div>
  
  
</div>
    
    }
  </>
))}</div> </div>   </>
  );
}
