import React,{useState,useEffect,useContext} from 'react'
import Login from './Login'
import Dashboard from './Dashboard'
import Search from './SearchGuest'
import AdminDashboard from './AdminDashboard'
import Footer from './Footer'
import { useAuth } from './AuthContext';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Home() {
  const { logout,user } = useAuth();
  if(user && user.status!=="enabled"){
    logout()  
    return toast.error("Account isn't Approved Yet Please Contact Admin")
   }
 else if(user && user.role!=="admin"){
    return(<><Dashboard/><Footer/></>)
   }
 else if(user && user.role=="guest"){
    return(<><Search/><Footer/></>)
   }
  else if(user && user.role=="admin"){
    return(<><AdminDashboard/><Footer/></>)
   }
  else{
  return(<><Login/><Footer/></>)
 }}
