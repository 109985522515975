import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import theme, { WEB } from "../theme";
export default function ViewClient() {
  const navigate = useNavigate();
  const location = useLocation();
  const client = location.state && location.state.clientData;

  return (
    <>
      <Navbar />

      <div className="container ">
        <div className="container bg-light mb-3 mt-2 text-dark">
          <div className="col pt-2">
            <div className="d-flex row">
              <div className="col  center">
                {client.avatar == null ? null : (
                  <img
                    style={{
                      height: "15rem",
                      width: "15rem",
                      objectFit: "contain",
                    }}
                    src={`${WEB.Backend}node/uploads/${client.avatar}`}
                  />
                )}
              </div>

              <div className="col">
                {client.photo1 == null ? null : (
                  <img
                    style={{ height: "7rem", width: "7rem" }}
                    src={`${WEB.Backend}node/uploads/${client.photo1}`}
                  />
                )}
              </div>
              <div className="col">
                {client.photo2 == null ? null : (
                  <img
                    style={{ height: "7rem", width: "7rem" }}
                    src={`${WEB.Backend}node/uploads/${client.photo2}`}
                  />
                )}
              </div>
            </div>


            
          </div>
        
          
          <h5 className="mt-4" style={{ color: "black" }}>
            Proposal Details
          </h5>
        
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Type
              </label>
              <p>{client.proptype}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Share Type
              </label>
              <p>{client.shareamount}</p>
            </div>
          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Amount
              </label>
              <p>{client.amount}</p>
            </div>

            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Proposal Origin
              </label>
              <p>{client.propfor}</p>
            </div>
          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Proposal Priority
              </label>
              <p>{client.propprio}</p>
            </div>
          </div>

          <h5 className="mt-4" style={{ color: "black" }}>
            Personal Details
          </h5>
          <div style={theme.ROW}>
          <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Profile ID
              </label>
              <h4>{client.clientid.toUpperCase()}</h4>
            </div>
            </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Profile By
              </label>
              <p>{client.role !== null && client.role == "user" ? "Candidate" : "Matchmaker"}</p>
            </div>
          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Name
              </label>
              <p>{client.name}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Gender
              </label>
              <p>{client.gender}</p>
            </div>
          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Age
              </label>
              <p>{client.age}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Height
              </label>
              <p>{client.height}</p>
            </div>
          </div>

          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Cast
              </label>
              <p>{client.cast}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Maslak
              </label>
              <p>{client.maslak}</p>
            </div>
          </div>
          <div style={theme.ROW}>

            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Marital Status
              </label>
              <p>{client.maritalstatus}</p>
            </div>

            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Education
              </label>
              <p>{client.education}</p>
            </div>
          </div>
          <div style={theme.ROW}>
            {client.maritalstatus === "divorced" || client.maritalstatus === "divorcedchild" && (
              <div className="col">
                <label style={{ color: "black" }} className="labelclient">
                  Divorce Reason
                </label>
                <p>{client.divorcereason}</p>
              </div>
            )}
            {client.maritalstatus == "divorcedchild" && (
              <div className="col">
                <label style={{ color: "black" }} className="labelclient">
                  Children
                </label>
                <p>{client.children}</p>
              </div>
            )}

          </div>
          <div style={theme.ROW}>

            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Language
              </label>
              <p>{client.language}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Complexion
              </label>
              <p>{client.complexion}</p>
            </div>
          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Job
              </label>
              <p>{client.job}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Business
              </label>
              <p>{client.business}</p>
            </div>
          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Income
              </label>
              <p>{client.income}</p>
            </div>
          </div>
          <h5 className="mt-4" style={{ color: "black" }}>
            Residence Details
          </h5>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Belongs
              </label>
              <p>{client.belongs}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Country
              </label>
              <p>{client.country}</p>
            </div>
          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Province
              </label>
              <p>{client.province}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                City
              </label>
              <p>{client.city}</p>
            </div>
          </div>
          <h5 className="mt-4" style={{ color: "black" }}>
            Family Details
          </h5>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Area
              </label>
              <p>{client.area}</p>
            </div>

            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Family Status
              </label>
              <p>{client.familystatus}</p>
            </div>
          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Full Address
              </label>
              <p>{client.fulladdress}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Home Type
              </label>
              <p>{client.hometype}</p>
            </div>
          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Home Size
              </label>
              <p>{client.homesize}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Father
              </label>
              <p>{client.father}</p>
            </div>
          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Mother
              </label>
              <p>{client.mother}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Brothers
              </label>
              <p>{client.brothers}</p>
            </div>
          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Sisters
              </label>
              <p>{client.sisters}</p>
            </div>
          </div>
          <h5 className="mt-4" style={{ color: "black" }}>
            Requirement Details
          </h5>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Age
              </label>
              <p>{client.reqage}-{client.reqageto}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Marital Status
              </label>
              <p>{client.reqmaritalstatus}</p>
            </div>

          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Maslak
              </label>
              <p>{client.reqmaslak}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Height
              </label>
              <p>{client.reqheight}</p>
            </div>
          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Education
              </label>
              <p>{client.reqeducation}</p>
            </div>

            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Cast
              </label>
              <p>{client.reqcast}</p>
            </div>
          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Family Status
              </label>
              <p>{client.reqfamilystatus}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                City
              </label>
              <p>{client.reqcity}</p>
            </div>

          </div>
          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Province
              </label>
              <p>{client.reqprovince}</p>
            </div>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Country
              </label>
              <p>{client.reqcountry}</p>
            </div>

          </div>


          <div style={theme.ROW}>
            <div className="col">
              <label style={{ color: "black" }} className="labelclient">
                Description
              </label>
              <p>{client.description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
